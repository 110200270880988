<template>
    <div class="l-main bg-white">
        <div class="l-main-content">
        <div class="row">
            <div class="col-4">
                <date-picker v-model="range" range class="input inline"></date-picker>
            </div>
            <div class="col-6">
                <div class="input inline">
                    <input v-on:keyup.enter="searchRecord" type="text" placeholder="Search..." v-model="search_match">
                </div>
            </div>
             <div class="col-2 text-right">
                <button class="button primary"  id="button-export" @click="generateExport">Export Profile </button>
            </div>
        </div>
        <div class="row">
            <loader v-show='loading' />
            <div class="overflow">
                <vuetable ref="vuetable"
                      :api-url="url"
                      :fields="columns"
                      :css="css.table"
                      :sort-order="sortOrder"
                      track-by="mpesa_paybill"
                      :append-params="moreParams"
                      :per-page="10"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded">
                      <div class="table-button-container" slot="action-slot" slot-scope="props">
                    <button class="button primary" @click="editRecords(props.rowData)"><i class="fa fa-eye" aria-hidden="true"></i> View </button>
                        
                        
                    </div>
                </vuetable>
            </div>
            <div class="p-top"></div>
            <div class="vuetable-pagination row">
                <div class="col text-center">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                </div>
                <div class="col">
                    <vuetable-pagination 
                        ref="pagination" 
                        @vuetable-pagination:change-page="onChangePage">
                    </vuetable-pagination>
                </div>
            </div>

        </div>
        </div>
        <modal name="edit" transition="pop-out" :width="600" :focus-trap="true" :height="500">
  <div class="box">
    <div class="box-part" id="bp-left">
      <div class="partition" id="partition-register">
        <div class="partition-title">Update Account</div>
        <div class="partition-form">
          <form autocomplete="false">
             <input id="n-username" type="text" placeholder="First Name" v-model="fname">
            <input id="n-username" type="text" placeholder="Middle Name" v-model="mname">
            <input id="n-username" type="text" placeholder="Last Name" v-model="lname">
              <select v-model="gender">
                <option value>Select Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            <input  type="text" placeholder="National ID" v-model="idNumber">
             <model-select :options="counties"
                                v-model="county"
                                 @change="getSubCounties($event)"
                                placeholder="select County">
         </model-select>

            <model-select :options="subcounties"
                                v-model="subcounty"
                                placeholder="select Sub County" v-show="showSub">
         </model-select>
          </form>

          <div style="margin-top: 42px">
          </div>

          <div class="button-set">
            <button id="goto-signin-btn" @click="editAction">Update</button>
          </div>

        </div>
      </div>
    </div>
    <div class="box-part" id="bp-right">
      <div class="box-messages">
      </div>
    </div>
  </div>
</modal>
    </div>
    
</template>

<style lang="scss">
$background_color: #404142;
$github_color: #dba226;
$facebook_color: #3880ff;
.box {
  background: white;
  overflow: hidden;
  width: 656px;
  height: 500px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;
  font-size: 0;
  .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    width: 90%;
   
  }
  .box-messages {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .box-error-message {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 0;
    line-height: 32px;
    padding: 0 12px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: white;
    background: #f38181;
  }
  .partition {
    width: 100%;
    height: 100%;
    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
    }
    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  input[type='password'],
  input[type='text'] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }
  select {
       display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }
  button {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: 0.1s all;
    font-size: 10px;
    &:hover {
      border-color: mix(#dddedf, black, 90%);
      color: mix(#8b8c8d, black, 80%);
    }
  }
  
  .large-btn {
    width: 100%;
    background: white;
    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
  .button-set {
    margin-bottom: 8px;
  }
  #register-btn,
  #signin-btn {
    margin-left: 8px;
  }
  .facebook-btn {
    border-color: $facebook_color;
    color: $facebook_color;
    &:hover {
      border-color: $facebook_color;
      background: $facebook_color;
    }
  }
  .github-btn {
    border-color: $github_color;
    color: $github_color;
    &:hover {
      border-color: $github_color;
      background: $github_color;
    }
  }
  .autocomplete-fix {
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>

<script>

    import store from "../store";
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import tableCss from "@/components/css.js";
    import DatePicker from 'vue2-datepicker';
    import { ModelSelect } from 'vue-search-select'
    import 'vue2-datepicker/index.css';

    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,DatePicker,ModelSelect
        },
        name: 'approvals',

        data() {
            return {
                counties:[],
                subcounties:[],   
                url:"https://api.v1.interactive.madfun.com/api/user/v1/profiles/view",
                css: tableCss,
                loading: true,
                showSub:false,
                selected_data: {},
                email:"",
                fname:"",
                mname:"",
                lname:"",
                idNumber:"",
                county:"",
                subcounty:"",
                gender:"",
                columns: [
                    
                    {
                        name: "msisdn",
                        title: 'Phone',
                        sortField: 'msisdn',
                    },
                    {
                        name: "first_name",
                        title: 'First Name',
                        sortField: 'first_name',
                    },
                    {
                        name: "last_name",
                        title: 'Last Name',
                        sortField: 'last_name',
                    },
                    {
                        name: "surname",
                        title: 'Surname',
                        sortField: 'surname',
                    },
                    {
                        name: "network",
                        title: 'Netwrok',
                        sortField: 'network',
                    },
                    {
                        name: "frequency",
                        title: 'Frequency of Use',
                        sortField: 'frequency',
                    },
                    {
                        name: 'created',
                        title: 'Date',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'action-slot',
                        title: 'Action',
                    }
                    
                ],
                range:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    end: ''
                },

            }
        },


        mounted() {
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';

            var vm = this;
            if(!this.$cookies.get("key")){
                this.$router.push({
                name: 'login'
            });
            }
            vm.url = "https://api.v1.interactive.madfun.com/api/user/v1/profiles/view";
            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }
            vm.getCounties();
            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },




        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.$refs.vuetable.refresh();
            }
        },


        methods: {
             editRecords(data) {

            let vm = this
            vm.email = data.email_address
            vm.fname  =  data.first_name
            vm.mname = data.surname
            vm.lname = data.last_name
            vm.gender = data.gender
            vm.idNumber = data.national_id
            vm.county = data.county
             this.$modal.show('edit');
            console.log("Data here " + JSON.stringify(data))
            
            
        },
        editAction(){

        },
        getCounties(){
            let vm = this
            $.ajax({
                    url: store.state.rootUrl + "api/profile/v1/view/county",
                    type: "POST",
                    data: JSON.stringify({
                        api_key: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        var dataRecord = response.data.data;

                        dataRecord.forEach(function(data){
                        vm.counties.push({
                            "value": data["county_name"],
                            "text": data["county_name"],
                        }
                        );
                        });
                    },
                    error: function(jQxhr, status, error) {
                        console.log(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            getSubCounties(event){
                let vm = this
                console.log("am here test "+ event.target.value);
                vm.showSub = true
                $.ajax({
                        url: store.state.rootUrl + "api/profile/v1/view/county",
                        type: "POST",
                        data: JSON.stringify({
                            api_key: vm.$cookies.get("key"),
                            county: lastSelectItem,
                            isSub: true
                        }),
                        success: function(response, status, jQxhr) {
                            var dataRecord = response.data.data;

                            dataRecord.forEach(function(data){
                            vm.subcounties.push({
                                "value": data["sub_county_name"],
                                "text": data["sub_county_name"],
                            }
                            );
                            });
                        },
                        error: function(jQxhr, status, error) {
                            console.log(jQxhr.responseJSON.statusDescription);
                        }
                    });
            },
            generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: "http://35.195.83.76/kenaff_app/api/profile/v1/view?export=1&start="+vm.moreParams.start+"&end="+vm.moreParams.end ,
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key_biz"),
                    }),
                    success: function(response, status, jQxhr) {
                         var item = {};
                            item.msisdn = "Phone";
                            item.first_name = "First Name";
                            item.last_name = "Last Name";
                            item.surname = "Surname";
                            item.gender = "Gender";
                            item.membership = "Membership #";
                            item.county = "County";
                            item.sub_county = "Sub County";
                            item.national_id = "National Id";
                            item.frequency_of_use = "Frequency of Use";
                            item.created = "Date";
                            exportRecipients.push(item);

                        for (var i = 0; i < response.data.length; i++) {
                            
                            var item = {};
                            item.msisdn = response.data[i].msisdn;
                            item.first_name = response.data[i].first_name;
                            item.last_name = response.data[i].last_name;
                            item.surname = response.data[i].surname;
                            item.gender = response.data[i].gender;
                            item.membership = response.data[i].profile_id;
                            item.county = response.data[i].county;
                            item.sub_county = response.data[i].sub_county;
                            item.national_id = response.data[i].national_id;
                            item.frequency_of_use = response.data[i].frequency_of_use;
                            item.created = response.data[i].created;
                            exportRecipients.push(item);
                        }
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                        vm.uploadStatus =  false;
                        $('#button-export').html('Export Profile ');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Profile_Report_"+exportDate);
                        
                        
    
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Kenaff_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                this.$refs.vuetable.refresh();

                console.log("start date filter",start)
                console.log("end date filter ",end)
            },
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            searchRecord:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.refresh();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here');
                $(".ui.dropdown").dropdown();
            },




        },
        


    }

</script>