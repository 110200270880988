<template>
<div class="l-main">
    <div class="l-main-content">
        <div class="row p-left p-right p-top">
            <div class="col-max">
                <div class="input">
                    <i class="search icon"></i>
                    <input v-on:keyup.enter="search_profile" type="text" placeholder="Enter phone number..." v-model="searchProfile">
                </div>
            </div>
            <div class="col-min p-left"></div>
            <div class="col-min p-left"></div>
        </div>
    </div>
    <div class="l-main-content padded" v-show="!profile_data">
        <div class="row">
            <h2 class="text-red">{{errorMessage}}</h2>
        </div>
    </div>
    <loader v-show="isLoading"></loader>
    <div class="l-main-content padded" v-show="profile_data">
        <div class="row">
            <div class="card bg-white">
                <div class="row vertical-middle">
                    <div class="col-6 vertical-middle">
                        <h4>{{name}}</h4>
                        <h3>{{phone}}</h3>
                        <div class="divider"></div>
                        <h5>Email</h5>
                        <h4><strong>{{email}}</strong></h4>
                    </div>
                    <div class="col-6 border-left padding-left-md">
                        <table class="table clean">
                            <tbody>
                                <tr>
                                    <td>Network</td>
                                    <td class="text-right"> {{network}}</td>
                                </tr>
                                <tr>
                                    <td>Frequency Use</td>
                                    <td class="text-right">{{frequency}}</td>
                                </tr>
                                <tr>
                                    <td>Created</td>
                                    <td class="text-right">{{created | format_date}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<loader v-show="!profile_data" />-->
    <tabs v-show="profile_data">

        <tab title="Payment">
            <div class="row padded">
                <loader v-show='loading' />
                <div class="overflow">
                    <vuetable ref="vuetablePayment" 
                    api-url="https://api.v1.interactive.madfun.com/v1/api/event/mpesa/transaction" 
                    :fields="columns" :css="css.table" :sort-order="sortOrder" 
                    track-by="paybill" :append-params="moreParamsPayment" :per-page="10"
                     @vuetable:pagination-data="onPaginationDataPayment" 
                     @vuetable:loading="onLoadingPayment" 
                     @vuetable:loaded="onLoadedPayment">
                      <div class="table-button-container" slot="action" slot-scope="props">
                            <button class="button primary" @click="resendSMS(props.rowData)">
                            <i class="fa fa-share" ></i> Resend  </button>
                        </div>
                    </vuetable>
                </div>
                <div class="p-top"></div>
                <div class="vuetable-pagination row">
                    <div class="col text-center">
                        <vuetable-pagination-info 
                        ref="paginationInfoPayment"></vuetable-pagination-info>
                    </div>
                    <div class="col">
                        <vuetable-pagination ref="paginationPayment" 
                        @vuetable-pagination:change-page="onChangePagePayment">
                        </vuetable-pagination>
                    </div>
                </div>

            </div>
        </tab>
        <tab title="Outbox Messages">
            <div class="row padded">
                <loader v-show='loading' />
                <div class="overflow">
                    <vuetable ref="vuetableMessages" 
                    api-url="https://api.v1.interactive.madfun.com/api/customer/v1/outbox"
                     :fields="columnsOutbox" :css="css.table" :sort-order="sortOrder" 
                     track-by="msisdn" :append-params="moreParamsMessages" :per-page="10"
                      @vuetable:pagination-data="onPaginationDataMessages" 
                      @vuetable:loading="onLoadingMessages" @vuetable:loaded="onLoadedMessages">
                    </vuetable>
                </div>
                <div class="p-top"></div>
                <div class="vuetable-pagination row">
                    <div class="col text-center">
                        <vuetable-pagination-info ref="paginationInfoMessages"></vuetable-pagination-info>
                    </div>
                    <div class="col">
                        <vuetable-pagination ref="paginationMessages" @vuetable-pagination:change-page="onChangePageMessages">
                        </vuetable-pagination>
                    </div>
                </div>

            </div>
        </tab>
        <tab title="Paid Tickets">
            <div class="row padded">
                <loader v-show='loading' />
                <div class="overflow">
                     <vuetable ref="vuetableTickets" 
                     api-url="https://api.v1.interactive.madfun.com/v1/api/event/ticket/purcahse/view?status=1" 
                     :fields="columnsTickets"
                      :css="css.table"
                       :sort-order="sortOrder"
                        track-by="msisdn" :append-params="moreParamsTickets"
                        :per-page="10" @vuetable:pagination-data="onPaginationDataTickets" 
                        @vuetable:loading="onLoadingTickets" @vuetable:loaded="onLoadedTickets">

                        <div slot="status" slot-scope="props">
                            <div><strong>{{props.rowData.status | formatStatus}}</strong></div>
                        </div>
                        
                    </vuetable>
                </div>
                <div class="p-top"></div>
                <div class="vuetable-pagination row">
                    <div class="col text-center">
                        <vuetable-pagination-info ref="paginationInfoTicket"></vuetable-pagination-info>
                    </div>
                    <div class="col">
                        <vuetable-pagination ref="paginationTicket" @vuetable-pagination:change-page="onChangePageTickets">
                        </vuetable-pagination>
                    </div>
                </div>
            </div>
        </tab>
        <tab title="UnPaid Tickets">
            <div class="row padded">
                <loader v-show='loading' />
                <div class="overflow">
                     <vuetable ref="vuetableUnPaid" 
                     api-url="https://api.v1.interactive.madfun.com/v1/api/event/ticket/purcahse/view?status=2" 
                     :fields="columnsUnPaidTickets" :css="css.table" :sort-order="sortOrder" 
                     track-by="msisdn" :append-params="moreParamsUpaid" :per-page="10" 
                     @vuetable:pagination-data="onPaginationDataUnPaid" 
                     @vuetable:loading="onLoadingUnPaid" 
                     @vuetable:loaded="onLoadedUnPaid">
                        <div slot="status" slot-scope="propsUnpaid">
                            <div><strong>{{propsUnpaid.rowData.status | formatStatus}}</strong></div>
                        </div>
                    </vuetable>
                </div>
                <div class="p-top"></div>
                <div class="vuetable-pagination row">
                    <div class="col text-center">
                        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                    </div>
                    <div class="col">
                        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePageUnPaid">
                        </vuetable-pagination>
                    </div>
                </div>
            </div>
        </tab>

    </tabs>

</div>
</template>

<style src="vue-slim-tabs/themes/default.css"></style>

<script>
import $ from 'jquery';
import {
    Tabs,
    Tab
} from 'vue-slim-tabs'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import numeral from "numeral";
import moment from 'moment'
import _ from "lodash";
import loader from "@/components/loader"
import tableCss from "@/components/css.js";
import store from "../store";

export default {

    name: 'profiles',

    components: {

        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        loader,
        Tabs,
        Tab,

    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            profile_data: false,
            searchProfile: "",
            name: "",
            phone: "",
            email: "",
            errorMessage: "",
            network: "",
            frequency: 0,
            created: "",
            css: tableCss,
            selectedTab: "default",
            loading: true,
            noRecordsStatus: false,
            selected_data: {},
            columns: [{
                    name: 'mpesa_sender',
                    title: 'Name',
                    sortField: 'mpesa_sender',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'mpesa_msisdn',
                    title: 'Phone',
                    sortField: 'mpesa_msisdn',
                    titleClass: '',
                    dataClass: '',

                },
                {
                    name: "mpesa_code",
                    title: 'Mpesa Code',
                    sortField: 'mpesa_code',
                },
                {
                    name: "mpesa_amount",
                    title: 'Amount',
                    sortField: 'mpesa_amount',
                },
                {
                    name: "mpesa_account",
                    title: 'Account',
                    sortField: 'mpesa_account',
                },
                {
                    name: "paybill",
                    title: 'Paybill',
                    sortField: 'paybill',
                },

                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                    titleClass: '',
                    dataClass: ''

                },
                'action'

            ],
            columnsOutbox: [{
                    name: "msisdn",
                    title: 'Phone',
                    sortField: 'msisdn',
                },
                {
                    name: "first_name",
                    title: 'Name',
                    sortField: 'first_name',
                },
                {
                    name: "created_by",
                    title: 'Source',
                    sortField: 'created_by',
                },

                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                    titleClass: '',
                    dataClass: ''

                },
                {
                    name: "message",
                    title: 'Message',
                    sortField: 'message',
                },

            ],
            columnsTickets: [{
                    name: "msisdn",
                    title: 'Phone',
                    sortField: 'msisdn',
                },
                {
                    name: "last_name",
                    title: 'First Name',
                    sortField: 'first_name',
                },
                {
                    name: "eventName",
                    title: 'Event Name',
                    sortField: 'eventName',
                },
                {
                    name: "venue",
                    title: 'Venue',
                    sortField: 'venue',
                },
                {
                    name: "amount",
                    title: 'Amount',
                    sortField: 'amount',
                },
                {
                    name: "status",
                    title: 'Status',
                    sortField: 'status',
                },

                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                    titleClass: '',
                    dataClass: ''

                },
                'action',

            ],
            columnsUnPaidTickets: [{
                    name: "msisdn",
                    title: 'Phone',
                    sortField: 'msisdn',
                },
                {
                    name: "last_name",
                    title: 'First Name',
                    sortField: 'first_name',
                },
                {
                    name: "eventName",
                    title: 'Event Name',
                    sortField: 'eventName',
                },
                {
                    name: "venue",
                    title: 'Venue',
                    sortField: 'venue',
                },
                {
                    name: "amount",
                    title: 'Amount',
                    sortField: 'amount',
                },
                {
                    name: "status",
                    title: 'Status',
                    sortField: 'status',
                },

                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                    titleClass: '',
                    dataClass: ''

                },

            ],
            range: '',
            search_match: '',
            loadOnStart: true,
            sortOrder: [{
                field: 'created',
                direction: 'desc'
            }],
            is_partial_resulting: false,

            moreParamsPayment: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsMessages: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsTickets: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsUpaid: {
                start: '',
                filter: '',
                end: ''
            },
        }
    },
    watch: {
        data(newVal, oldVal) {
            let vm = this
            vm.$refs.vuetablePayment.refresh();
            vm.$refs.vuetableMessages.refresh();
            vm.$refs.paginationTickets.refresh();
            vm.$refs.vuetableUnPaid.refresh();
        },
        range() {

            var start = this.range[0];
            var end = this.range[1];
            start = moment(start).format('YYYY-MM-DD');
            end = moment(end).format('YYYY-MM-DD');
        },
        searchProfile: function(val) {
            if(val){
            let vm = this
            this.moreParamsMessages.filter = vm.searchProfile;
            this.moreParamsPayment.filter = vm.searchProfile;
            this.moreParamsTickets.filter = vm.searchProfile;
            this.moreParamsUpaid.filter = vm.searchProfile;
         
            // vm.$refs.vuetablePayment.refresh();
            // vm.$refs.vuetableMessages.refresh();
            // vm.$refs.paginationTickets.refresh();
            // vm.$refs.vuetableUnPaid.refresh();
            }
            
        }
    },

    mounted() {
    },
    methods: {
        
        resendSMS(data){
            console.log("data "+JSON.stringify(data))
            let vm = this
             vm.$swal({
                title: 'Are you sure?',
                text: 'The ticket will be resent to '+data.mpesa_sender+' for payment receipt '+data.mpesa_code,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, resent it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                if(result.value) {
                    $.post({
                        url: store.state.rootUrl + "v1/api/main/payment/retries",
                        type: "POST",
                        data: JSON.stringify({
                            api_key: vm.$cookies.get("key"),
                            mpesaReference:data.mpesa_code,
                            source:"WEB"
                        }),
                        success: function (response, status, jQxhr) {
                            if(response.data.code != 200){
                                vm.$swal('Error', "Failed to resent ticket.<br /> Reason:"+response.data.message[0].message, 'error')
                            }
                            else {
                                vm.$swal('Successful sent');
                                vm.$refs.vuetablePayment.refresh();
                            }
                        },
                        error: function (jQxhr, status, error) {
                             vm.$swal('Failed', 'Failed to sent ticket', 'error')
                        }
                    });
                    
                } else {
                    vm.$swal('Cancelled', 'Cancel ticket resent', 'info')
                }
                })
        },
        createdAt: function (value) {
            if (!value) {
                return '-';
            } else {
                return moment(value).format('h:mm a,DD MMM YYYY');
            }
        },
        searchRecord: function () {
            
            let vm = this
             this.moreParamsMessages.filter = vm.searchProfile;
            this.moreParamsPayment.filter = vm.searchProfile;
            this.moreParamsTickets.filter = vm.searchProfile;
            this.moreParamsUpaid.filter = vm.searchProfile;
         
            vm.$refs.vuetablePayment.refresh();
            vm.$refs.vuetableMessages.refresh();
            vm.$refs.paginationTickets.refresh();
            vm.$refs.vuetableUnPaid.refresh();
        },
        search_profile() {
            let vm = this;
            vm.isLoading = true;
             this.moreParamsMessages.filter = vm.searchProfile;
            this.moreParamsPayment.filter = vm.searchProfile;
            this.moreParamsTickets.filter = vm.searchProfile;
            this.moreParamsUpaid.filter = vm.searchProfile;
         
            vm.profile_data = false;
            vm.errorMessage = "";
            $.post({
                url: store.state.rootUrl + "api/customer/v1/info",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    msisdn: vm.searchProfile
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false;
                    if (response.data.record_count == 0) {
                        vm.profile_data = false;
                        vm.errorMessage = response.data.message;
                    } else {
                        vm.profile_data = true;
                        vm.name = response.data.data[0].first_name + " " + response.data.data[0].surname + " " + response.data.data[0].last_name
                        vm.phone = response.data.data[0].msisdn;

                        vm.email = response.data.data[0].email;
                        vm.network = response.data.data[0].network;
                        vm.frequency = response.data.data[0].frequency;
                        vm.created = response.data.data[0].created;
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false;
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.profile_data = false;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext;;
                    }
                }
            });
            setTimeout(function () {
               // $(".ui.menu").tab();
                $('.tabular.menu .item').tab();
            }, 200)

            vm.$refs.vuetablePayment.refresh();
            vm.$refs.vuetableMessages.refresh();
            vm.$refs.paginationTickets.refresh();
            vm.$refs.vuetableUnPaid.refresh();
        },
        onPaginationDataPayment(paginationData) {
            this.$refs.paginationInfoPayment.setPaginationData(paginationData)
            this.$refs.paginationPayment.setPaginationData(paginationData)
        },
        onChangePagePayment(page) {
            this.$refs.vuetablePayment.changePage(page)
        },

        onLoadingPayment() {
            this.loading = true
        },
        onLoadedPayment() {
            this.loading = false
        },
        onPaginationDataMessages(paginationData) {
            this.$refs.paginationInfoMessages.setPaginationData(paginationData)
            this.$refs.paginationMessages.setPaginationData(paginationData)
        },
        onChangePageMessages(page) {
            this.$refs.vuetableMessages.changePage(page)
        },

        onLoadingMessages() {
            this.loading = true
        },
        onLoadedMessages() {
            this.loading = false
        },
        onPaginationDataTickets(paginationData) {
            this.$refs.paginationInfoTickets.setPaginationData(paginationData)
            this.$refs.paginationTickets.setPaginationData(paginationData)
        },
        onChangePageTickets(page) {
            this.$refs.vuetableTickets.changePage(page)
        },

        onLoadingTickets() {
            this.loading = true
        },
        onLoadedTickets() {
            this.loading = false
        },
        onPaginationDataUnPaid(paginationData) {
            this.$refs.paginationInfoUnPaid.setPaginationData(paginationData)
            this.$refs.paginationUnPaid.setPaginationData(paginationData)
        },
        onChangePageUnPaid(page) {
            this.$refs.vuetableUnPaid.changePage(page)
        },

        onLoadingUnPaid() {
            this.loading = true
        },
        onLoadedUnPaid() {
            this.loading = false
        }

    },

    created: function () {
        let vm = this;
        //$('.menu .item').tab();
        vm.selectedTab == 'default';
    },
    filters: {
        formatAmount(value) {
            return numeral(value).format("0,0.00");
        },
        formatNumber(value) {
            return numeral(value).format("0,0");
        },
        format_date(value) {

            if (!value) {
                return '-';
            } else {
                return moment(value).format('DD MMM YYYY');
            }

        },
        formatStatus(value) {
            if (value != null) {
                if (value == 1) {
                    return "Paid"
                } else {
                    return "Unpaid"
                }

            }
            return ""
        }
    }

}
</script>
