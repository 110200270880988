<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col-6">
        <h3>All Users</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'addUser'}" class="button primary">Add User</router-link>
      </div>
    </div>
    <div class="row">
      <loader v-show="loading"/>
      <vuetable
        ref="vuetable"
        api-url="https://api.v1.interactive.madfun.com/api/user/v1/view"
        :fields="columns"
        :sort-order="sortOrder"
        track-by="msisdn"
        :append-params="moreParams"
        :per-page="10"
        @vuetable:pagination-data="onPaginationData"
        @vuetable:loading="onLoading"
        @vuetable:loaded="onLoaded"
      ></vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<style>
td.vuetable-slot {
  overflow: visible !important;
}
</style>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      loadRecord: false,
      selected_data: {},
      columns: [
        {
          name: "msisdn",
          title: "Phone",
          sortField: "msisdn",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "email",
          title: "Email",
          sortField: "email",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "role_name",
          title: "Role",
          sortField: "role_name",
          titleClass: "",
          dataClass: ""
        },
        {
          name: "first_name",
          title: '<span class="orange glyphicon glyphicon-user"></span> First Name',
          sortField: "first_name",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "last_name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Last Name',
          sortField: "last_name",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },

        
        {
          name: "created",
          title: "Date",
          sortField: "created",
          callback: "createdAt"
        }
      ],
      range: "",
      search_match: "",
      loadOnStart: true,
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      is_partial_resulting: false,

      moreParams: {
        start: "",
        filter: "",
        end: ""
      }
    };
  },

  mounted() {
    if(!this.$cookies.get("key")){
        this.$router.push({
        name: 'login'
      });
    }
    var vm = this;
    if (vm.$cookies.get("role_id") != 1) {
      vm.$router.push({ name: "dashboard" });
    }

  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
    range() {
      var start = this.range[0];
      var end = this.range[1];
      start = moment(start).format("YYYY-MM-DD");
      end = moment(end).format("YYYY-MM-DD");
      this.moreParams.start = start;
      this.moreParams.end = end;
      this.$refs.vuetable.refresh();
    }
  },

  methods: {
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    searchRecord: function() {
      this.loadRecord = true;
      var str = this.search_match;
      var newString = str.indexOf("0") == 0 ? str.substring(1) : str;
      this.moreParams.filter = "254" + newString;
      this.$refs.vuetable.refresh();
    },

    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log("pagination:", pagination);
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },

    onPaginationData(paginationData) {
      this.$refs.paginationInfo.setPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    onActionClicked(action, data) {
      // $('#modal-approvals').modal({centered: false}).modal("show");
      console.log("slot actions: on-click", data.parent_match_id);

      var vm = this;

      if (action == "match-priority") {
        vm.$swal({
          title: "Change Match Priority?",
          text: "If you are sure, type in the new Match priority:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Update",
          showLoaderOnConfirm: true,
          preConfirm: priority => {
            console.log("finally", priority);
            if (!priority) {
              return;
            }
            var data = {
              parent_match_id: vm.parent_match_id,
              priority: priority,
              user_id: 1
            };
            console.log("Payload | " + JSON.stringify(data));
            $.ajax({
              url: "http://odi.life/v1/dash/match/priority",
              type: "POST",
              data: JSON.stringify(data),
              success: function(response, textStatus, jQxhr) {
                console.log("Swap Matche: " + JSON.stringify(response));
                if (response.status_code == 200) {
                  vm.$swal("Success!", response.status_description, "success");

                  $("#switch-modall").modal("hide");
                } else {
                  vm.$swal("Error!", response.status_description, "error");
                }
              },
              error: function(jqXhr, textStatus, error) {
                vm.$swal("Error!", error, "error");
              }
            });
          }
        });
      }

      if (action == "sms-priority") {
        vm.$swal({
          title: "Change Sms Priority?",
          text: "If you are sure, type in the new Match priority:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Update",
          showLoaderOnConfirm: true,
          preConfirm: priority => {
            console.log("finally", priority);
            if (!priority) {
              return;
            }
            var data = {
              parent_match_id: vm.parent_match_id,
              priority: priority,
              user_id: 1
            };
            console.log("Payload | " + JSON.stringify(data));
            $.ajax({
              url: "http://odi.life/v1/dash/match/sms/priority",
              type: "POST",
              data: JSON.stringify(data),
              success: function(response, textStatus, jQxhr) {
                console.log("Swap Matche: " + JSON.stringify(response));
                if (response.status_code == 200) {
                  vm.$swal("Success!", response.status_description, "success");

                  $("#switch-modall").modal("hide");
                } else {
                  vm.$swal("Error!", response.status_description, "error");
                }
              },
              error: function(jqXhr, textStatus, error) {
                vm.$swal("Error!", error, "error");
              }
            });
          }
        });
      }
    },

    onActionVoid(action, data) {
      var vm = this;

      if (action == "view-item") {
        vm.$swal({
          title: "Match Voiding",
          text: "If you are sure, type in the  Void Factor:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Void Match",
          showLoaderOnConfirm: true,
          preConfirm: priority => {
            console.log("finallyyyyyyyyy", priority);
            if (!priority) {
              return;
            }
            var Voiddata = {
              parent_match_id: data.parent_match_id,
              void_factor: priority,
              user_id: 1
            };
            console.log("Payload | " + JSON.stringify(Voiddata));
            $.ajax({
              url: "http://35.189.221.84/dashboard-api/v1/odibets/resulting",
              type: "POST",
              data: JSON.stringify(Voiddata),
              success: function(response, textStatus, jQxhr) {
                console.log("Swap Matche: " + JSON.stringify(response));
                if (response.status_code == 200) {
                  vm.$swal("Success!", response.status_description, "success");

                  $("#switch-modall").modal("hide");
                } else {
                  vm.$swal("Error!", response.status_description, "error");
                }
              },
              error: function(jqXhr, textStatus, error) {
                vm.$swal("Error!", error, "error");
              }
            });
          }
        });
      } else {
      }
    },

    onActionBoost(action, data) {
      var vm = this;

      if (action == "view-item") {
        vm.$swal
          .fire({
            title: "Boost Match?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Boost Match!"
          })
          .then(result => {
            if (result.value) {
              var Voiddata = {
                parent_match_id: data.parent_match_id,
                user_id: 1,
                status: 1
              };
              console.log("Payload | " + JSON.stringify(Voiddata));
              $.ajax({
                url: "http://104.155.92.75/v1/dash/match/boost",
                type: "POST",
                data: JSON.stringify(Voiddata),
                success: function(response, textStatus, jQxhr) {
                  console.log("Swap Matche: " + JSON.stringify(response));
                  if (response.status_code == 200) {
                    vm.$swal(
                      "Success!",
                      response.status_description,
                      "success"
                    );

                    $("#switch-modall").modal("hide");
                  } else {
                    vm.$swal("Error!", response.status_description, "error");
                  }
                },
                error: function(jqXhr, textStatus, error) {
                  vm.$swal("Error!", error, "error");
                }
              });
            } else {
            }
          });
      } else {
        vm.$swal
          .fire({
            title: "Add Free bet Match?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Free bet Match!"
          })
          .then(result => {
            if (result.value) {
              var Voiddata = {
                parent_match_id: data.parent_match_id,
                user_id: 1
              };
              console.log("Payload | " + JSON.stringify(Voiddata));
              $.ajax({
                url: "http://104.155.92.75/v1/dash/free/match",
                type: "POST",
                data: JSON.stringify(Voiddata),
                success: function(response, textStatus, jQxhr) {
                  console.log("Swap Matche: " + JSON.stringify(response));
                  if (response.status_code == 200) {
                    vm.$swal(
                      "Success!",
                      response.status_description,
                      "success"
                    );

                    $("#switch-modall").modal("hide");
                  } else {
                    vm.$swal("Error!", response.status_description, "error");
                  }
                },
                error: function(jqXhr, textStatus, error) {
                  vm.$swal("Error!", error, "error");
                }
              });
            } else {
            }
          });
      }
    },
    onActionNewBoost(action, data) {
      var vm = this;

      if (action == "view-item") {
        vm.$swal
          .fire({
            title: "Boost Match?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Boost Match!"
          })
          .then(result => {
            if (result.value) {
              var Voiddata = {
                parent_match_id: data.parent_match_id,
                user_id: 1,
                status: 1
              };
              console.log("Payload | " + JSON.stringify(Voiddata));
              $.ajax({
                url: "http://104.155.92.75/v1/dash/match/boast",
                type: "POST",
                data: JSON.stringify(Voiddata),
                success: function(response, textStatus, jQxhr) {
                  console.log("Swap Matche: " + JSON.stringify(response));
                  if (response.status_code == 200) {
                    vm.$swal(
                      "Success!",
                      response.status_description,
                      "success"
                    );

                    $("#switch-modall").modal("hide");
                  } else {
                    vm.$swal("Error!", response.status_description, "error");
                  }
                },
                error: function(jqXhr, textStatus, error) {
                  vm.$swal("Error!", error, "error");
                }
              });
            } else {
            }
          });
      }
    },
    onActionMarkets(action, data) {
      var vm = this;

      var myObj = {
        match: data
      };

      vm.selected_data = data;

      console.log("slot actions: on-click" + JSON.stringify(vm.selected_data));
      $("#markets").modal("show");
    },
    onActionResulting(action, data) {
      // $('#modal-approvals').modal({centered: false}).modal("show");
      console.log("slot actions: on-click", data.parent_match_id);

      var vm = this;

      vm.$swal
        .fire({
          title: "New  Resulting?",
          text: "If you are sure, add to new resulting!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, New Resulting!"
        })
        .then(result => {
          if (result.value) {
            var Voiddata = {
              parent_match_id: data.parent_match_id,
              user_id: 1
            };
            console.log("Payload | " + JSON.stringify(Voiddata));
            $.ajax({
              url: "http://104.155.92.75/v1/dash/match/new",
              type: "POST",
              data: JSON.stringify(Voiddata),
              success: function(response, textStatus, jQxhr) {
                console.log("Swap Matche: " + JSON.stringify(response));
                if (response.status_code == 200) {
                  vm.$swal("Success!", response.status_description, "success");

                  $("#switch-modall").modal("hide");
                } else {
                  vm.$swal("Error!", response.status_description, "error");
                }
              },
              error: function(jqXhr, textStatus, error) {
                vm.$swal("Error!", error, "error");
              }
            });
          } else {
          }
        });
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
      $(".ui.dropdown").dropdown();
    }
  }
};
</script>