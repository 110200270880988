<template>
  <div class="l-container-content bg-white">
    <div class="row">
      <div class="col">
        <div class="text-title">
          <h3>Add Users</h3>
        </div>
        <div v-show="errorcreateStatus">
          <small class="text-red">{{errorsMessage}}</small>
        </div>
        <div v-show="successcreateStatus">
          <small class="text-success">{{successMessage}}</small>
        </div>
        <div class="l-form row">
          <div class="col">
            <div class="col-4">
            <label>Fist Name</label>
            <input type="text" v-model="fname" placeholder="Enter Fist Name">
            </div>
            <div class="col-4">
            <label>Surname</label>
            <input type="text" v-model="sname" placeholder="Enter Surname">
            </div>
            <div class="col-4">
            <label>Last Name</label>
            <input type="text" v-model="lname" placeholder="Enter Last Name">
            </div>
          </div>
          <div class="col">
            <div class="col-6">
              <label>Phone number</label>
              <input type="tel" v-model="phone" placeholder="Enter phone number">
            </div>
            <div class="col-6">
              <label>Email</label>
              <input type="email" v-model="email" placeholder="Enter Email">
            </div>
          </div>
          <div class="col">
            <div class="col-6">
              <label>Role</label>
              <select v-model="userRoles">
                <option value>Select Roles</option>
                <option value="1">Super Admin</option>
                <option value="2">Admin</option>
                <option value="3">Auditor</option>
                <option value="4">CS</option>
                <option value="6">Event Agent</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="col-4">
            <button class="button primary" id="createUser" @click="createUser()">Create User</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";
import loader from "@/components/loader";
import $ from "jquery";
export default {
  data: function() {
    return {
      phone: "",
      email: "",
      fname: "",
      sname: "",
      lname: "",
      userRoles: "",
      errorcreateStatus: false,
      successcreateStatus: false,
      errorsMessage: "",
      successMessage: "",
      counties:[]
    };
  },
  mounted() {
    if(!this.$cookies.get("key")){
          this.$router.push({
          name: 'login'
        });
      }
    let vm = this;
    if (vm.$cookies.get("role_id") != 1) {
      vm.$router.push({ name: "dashboard" });
    }
  },
  components: {
    loader
  },
  methods: {
    createUser() {
      $("#createUser").html('<i class="fa fa-spinner fa-spin"></i> Loading...');
      let vm = this;
      $.post({
        url: store.state.rootUrl + "api/user/v1/create",
        type: "POST",
        data: JSON.stringify({
          api_key: vm.$cookies.get("key"),
          fname: vm.fname,
          sname: vm.sname,
          lname: vm.lname,
          email: vm.email,
          msisdn: vm.phone,
          role_id: vm.userRoles,
          source: "MOBILE_APP"
        }),
        success: function(response, status, jQxhr) {
          vm.errorcreateStatus = false;
          vm.successcreateStatus = true;
          vm.successMessage = response.statusDescription;
          $("#createUser").text("Create User");
        },
        error: function(jQxhr, status, error) {
          $("#createUser").text("Create User");
          vm.errorcreateStatus = true;
          vm.successcreateStatus = false;
          vm.errorsMessage = jQxhr.responseJSON.statusDescription;
        }
      });
    },
    
  }
};
</script>
