<template>
    <div class="l-main bg-white">
        <div class="l-main-content">
        <div class="row">
            <div class="col-4">
                
                <date-picker v-model="range" range class="input inline"></date-picker>
                
            </div>
            <div class="col-6">
                <div class="input inline">
                    <input v-on:keyup.enter="searchRecord" type="text" placeholder="Search..." v-model="search_match">
                </div>
            </div>
            <div class="col-2 text-right">
                <button class="button primary"  id="button-export" @click="generateExport">Export Value Chain </button>
            </div>
        </div>

        <div class="row">
            <loader v-show='loading' />
            <div class="overflow">
                <vuetable ref="vuetable"
                      :api-url="url"
                      :fields="columns"
                      :css="css.table"
                      :sort-order="sortOrder"
                      track-by="paybill"
                      :append-params="moreParams"
                      :per-page="10"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded">
                </vuetable>
            </div>
            <div class="p-top"></div>
            <div class="vuetable-pagination row">
                <div class="col text-center">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                </div>
                <div class="col">
                    <vuetable-pagination 
                        ref="pagination" 
                        @vuetable-pagination:change-page="onChangePage">
                    </vuetable-pagination>
                </div>
            </div>

        </div>
        </div>
    </div>
</template>

<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>

<script>

    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import tableCss from "@/components/css.js";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,DatePicker
        },
        name: 'approvals',

        data() {
            return {
                url:"http://35.195.83.76/kenaff_app/api/profile/v1/value/chain?api_key="+this.$cookies.get("key"),
                css: tableCss,
                loading: true,
                selected_data: {},
                columns: [
                    {
                        name: 'name',
                        title: 'Product',
                        sortField: 'name',
                        titleClass: '',
                        dataClass: ''
                    },
                    {
                        name: 'chain_name',
                        title: 'Chain Name',
                        sortField: 'chain_name',
                        titleClass: '',
                        dataClass: '',

                    },
                    {
                        name: "msisdn",
                        title: 'Phone',
                        sortField: 'msisdn',
                    },
                    {
                        name: "first_name",
                        title: 'First Name',
                        sortField: 'first_name',
                    },
                    {
                        name: "last_name",
                        title: 'Last Name',
                        sortField: 'last_name',
                    },
                    {
                        name: "status",
                        title: 'Status',
                        sortField: 'status',
                    },
                   
                    {
                        name: 'created',
                        title: 'Date',
                        sortField: 'created',
                        titleClass: '',
                        dataClass: ''

                    },
                    
                ],
                range:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    end: ''
                },

            }
        },
        mounted() {
            //var headerHeight = document.getElementById('header').offsetHeight;
            //document.getElementById('main-content').style.top = headerHeight + 'px';
            if(!this.$cookies.get("key")){
                this.$router.push({
                name: 'login'
            });
            }
            var vm = this;
            vm.url = "http://35.195.83.76/kenaff_app/api/profile/v1/value/chain?api_key="+ vm.$cookies.get("key");

            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

            $('input[name="dates"]').daterangepicker({
                startDate: start,
                endDate: end,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);

        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.$refs.vuetable.refresh();
            }
        },
        methods: {
            generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: "http://35.195.83.76/kenaff_app/api/profile/v1/value/chain?export=1&start="+vm.moreParams.start+"&end="+vm.moreParams.end ,
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key_biz"),
                    }),
                    success: function(response, status, jQxhr) {
                         var item = {};
                            item.name = "Product";
                            item.chain_name = "Chain Name";
                            item.msisdn = "Phone";
                            item.first_name = "First Name";
                            item.last_name = "Last Name";
                            item.status = "Status";
                            item.created = "Date";
                            exportRecipients.push(item);

                        for (var i = 0; i < response.data.length; i++) {
                            
                            var item = {};
                            item.name = response.data[i].name;
                            item.chain_name = response.data[i].chain_name;
                            item.msisdn = response.data[i].msisdn;
                            item.first_name = response.data[i].first_name;
                            item.last_name = response.data[i].last_name;
                            item.status = response.data[i].status;
                            item.created = response.data[i].created;
                            exportRecipients.push(item);
                        }
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                        vm.uploadStatus =  false;
                        $('#button-export').html('Export Value Chain ');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Value_Chain_Report_"+exportDate);
                        
                        
    
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Kenaff_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
            },

            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                this.$refs.vuetable.refresh();

                console.log("start date filter",start)
                console.log("end date filter ",end)
            },
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            searchRecord:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.refresh();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here');
                $(".ui.dropdown").dropdown();
            },




        },


    }

</script>