<template>
    <div class="l-navigation">
        <div class="l-navigation-bg"></div>
        
        <div class="l-navigation-container">
            <svg xmlns="http://www.w3.org/2000/svg" height="90px" style="margin-left: 10px; margin-top: 10px;" viewBox="0 0 186 166.369">
                        <g id="Group_6" data-name="Group 6" transform="translate(-236.933 -61.37)">
                            <path id="Path_2" data-name="Path 2" d="M406.5,184.862c-23.269,40.647-74.787,54.9-115.069,31.843s-69.512-83.543-46.243-124.19S350.855,55.288,391.137,78.348,429.772,144.215,406.5,184.862Z" transform="translate(0)" fill="#ffd100"/>
                            <g id="Group_4" data-name="Group 4" transform="translate(267.779 98.291)">
                                <path id="Path_3" data-name="Path 3" d="M437.257,163.685c-1.182-11.348-10.325-17.427-22.562-16.153l-19.061,1.905,4.8,36.332-18.54-34.7-.759.079-11.987,2.948-12.653,36.3L352.6,154l-10.143,1.056-9.579,20.715L318.922,157.5l-10.143,1.056,3.811,36.6,11.241-1.17-.806-14.983,8.412,10.861,5.438-.566,6.638-13.467,1.76,15.922,11.243-1.17,31.285-3.257,12.76-1.328,17.946-1.868C430.743,182.863,438.438,175.031,437.257,163.685Z" transform="translate(-308.779 -147.365)" fill="#101820"/>
                            </g>
                            <g id="Group_5" data-name="Group 5" transform="translate(273.101 136.379)">
                                <path id="Path_4" data-name="Path 4" d="M336.388,270.022l.726,6.248,17.2-2,.273,10.592-16.162,2.65,1.445,12.43-13.9,1.616-4.793-41.225,33.509-3.9,1.219,10.482Z" transform="translate(-321.175 -247.697)" fill="#101820"/>
                                <path id="Path_5" data-name="Path 5" d="M543.847,236.077l2.6,41.421-11.479.72-16.2-17.066.456,18.053-13.609.854-2.6-41.421,11.479-.72,16.323,17.2-.582-18.186Z" transform="translate(-424.946 -236.077)" fill="#101820"/>
                            </g>
                            <path id="Path_6" data-name="Path 6" d="M445.685,270.028l-1.639-22.768-13.718.988h0l-12.656.911h0l-13.954,1.005,1.639,22.768c.894,12.418,8.861,19.037,21.573,18.121S446.579,282.446,445.685,270.028Z" transform="translate(-95.177 -106.08)" fill="#101820"/>
                            <path id="Path_7" data-name="Path 7" d="M414.628,261.242l30.987-2.153.088,5.2s-11.539,7.544-30.552,1.8Z" transform="translate(-101.403 -112.83)" fill="#fff"/>
                            <path id="Path_8" data-name="Path 8" d="M431.835,312.766s-1.257-5.09,8.322-4.082c4.863.512,1.647,4.958,1.647,4.958s10.787-5.307,11.015-1.121C453.06,316.974,435.693,324.536,431.835,312.766Z" transform="translate(-111.191 -141.056)" fill="#ea0029"/>
                            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="6.945" cy="8.365" rx="6.945" ry="8.365" transform="translate(326.598 118.238) rotate(-25.644)" fill="#fff"/>
                            <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="4.792" cy="4.838" rx="4.792" ry="4.838" transform="translate(332.731 115.326)" fill="#101820"/>
                            <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="1.7" cy="1.611" rx="1.7" ry="1.611" transform="translate(334.156 118.887)" fill="#fff"/>
                            <path id="Path_9" data-name="Path 9" d="M441.278,143.368l18.657-.462.059-2.182L440.54,138.2Z" transform="translate(-116.19 -43.843)" fill="#101820"/>
                        </g>
                    </svg>
            <ul>
                <li :class="{'active': this.$route.name == 'dashboard'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'dashboard'}"><i class="fa fa-chart-pie fa-fw"></i><span>Dashboard</span></router-link>
                </li>

                 <li :class="{'active': this.$route.name == 'view-events' || this.$route.name == 'view-events-dashboard'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'view-events'}"><i class="fa fa-address-card fa-fw"></i><span>View Events</span></router-link>
                </li>
                <li :class="{'active': this.$route.name == 'ticketPurchased'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'ticketPurchased'}"><i class="fa fa-calendar fa-fw"></i><span>Ticket Purchased</span></router-link>
                </li>
                <li :class="{'active': this.$route.name == 'view-mpesa'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'view-mpesa'}"><i class="fa fa-credit-card" aria-hidden="true"></i><span>M-Pesa Transaction</span></router-link>
                </li>
                               
                <li :class="{'active': this.$route.name == 'profiles-view'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'profiles-view'}"><i class="fa fa-users fa-fw"></i><span>View Profiles</span></router-link>
                </li>
                <li :class="{'active': this.$route.name == 'customers'}" v-show="stateAdmin || stateAuditor">
                    <router-link :to="{name: 'customers'}"><i class="fa fa-user fa-fw"></i><span>Customers</span></router-link>
                </li>
               
                <li class="p-top p-bottom"></li>
                <li class="" :class="{'active': this.$route.name == 'admin'}" v-show="stateAdmin">
                    <router-link :to="{name: 'admin'}"><i class="fa fa-star fa-fw"></i><span>Admin</span></router-link>
                </li>

                <li class="p-top p-bottom"></li>
            </ul>
            <div class="l-navigation-options">
            <button v-on:click="logout()" class="button white hidden">Logout</button>
            </div>
        </div>
    </div>
</template>
<script>
import store from "../store";
import $ from 'jquery'
export default {
     data:function(){
        return{
            stateAdmin:false,
            stateAuditor:false,
        }
    },
    mounted() {
         if(!this.$cookies.get("key")){
            this.$router.push({
            name: 'login'
         });
        }
        var vm = this;
        $(".l-navigation .l-navigation-container ul li").each(function(){
            $(this).click(function(){
                vm.$parent.showNav = false;
            });
        });
        $(".l-navigation-bg").click(function(){
            vm.$parent.showNav = false;
        });
        if(vm.$cookies.get("role_id") == 1){
            vm.stateAdmin = true;
            
        }
        if(vm.$cookies.get("role_id") == 2){
            vm.stateAuditor = true
        }
    },
    methods: {
       logout() {
            let vm = this;
            vm.$cookies.remove("key");
            vm.$cookies.remove("username");
            vm.$cookies.remove("role_id");
            vm.$router.push({
                name: 'login'
            });
        },
       
    }
}
</script>