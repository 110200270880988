<template>
    <div class="l-main bg-white">
        <div class="l-main-content">
            <div class="row">
    
    
                <div class="col-8">
    
                    <div class="input inline">
                        <input v-on:keyup.enter="searchRecord" type="text" placeholder="Search..." v-model="search_match">
                    </div>
                </div>
                <div class="col-4">
    
                    <date-picker v-model="range" range class="inline" placeholder="Select Date"></date-picker>
                </div>
    
            </div>
            <div class="row">
                <div class="col-6">
                    <button class="button primary" @click="applyEvent"><i class="fa fa-plus" aria-hidden="true"></i> Add Event </button>
                    <button class="button primary" id="button-export" @click="generateExport"><i class="fa fa-download" aria-hidden="true"></i> Export Events </button>
                </div>
    
            </div>
            <loader v-show='uploadStatus' />
            <div class="row">
                <loader v-show='loading' />
                <div class="overflow">
                    <vuetable ref="vuetable" api-url="https://api.v1.interactive.madfun.com/v1/api/event/dashboard/view" :fields="columns" :css="css.table" :sort-order="sortOrder" track-by="msisdn" :append-params="moreParams" :per-page="10" @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
    
                        <div class="table-button-container" slot="action" slot-scope="props">
                            <button class="button primary" @click="viewRecords(props.rowData)"><i class="fa fa-eye" aria-hidden="true"></i> View </button>
                            <button class="button secondary" @click="selectedEvents(props.rowData)"><i class="fa fa-edit" aria-hidden="true"></i> Edit </button>
                        </div>
                        <div slot="status" slot-scope="props">
                            <div :class="{'text-green':props.rowData.status== 1,'text-red':props.rowData.status== 3, 'text-orange': props.rowData.status== 4 }"><strong>{{props.rowData.status | formatStatus}}</strong></div>
                        </div>
                    </vuetable>
                </div>
                <div class="p-top"></div>
    
                <div class="vuetable-pagination row">
                    <div class="col text-center">
                        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                    </div>
                    <div class="col">
                        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage">
                        </vuetable-pagination>
                    </div>
                </div>
    
            </div>
            <div class="row">
                <vue-modal-2 width="500" name="modal" @on-close="close" :headerOptions="{
                                                title: 'Create New Event',
                                            }" :footerOptions="{
                                                btn1: 'Cancel',
                                                btn2: 'Create Events',
                                                btn1Style: {
                                                backgroundColor: '#C70039',
                                                color:'white'
                                                },
                                                btn2Style: {
                                                backgroundColor: '#FFC300',
                                                color:'black'
                                                },
                                                btn2OnClick: () => {
                                                    createEvents();
                                                },
                                                btn1OnClick: () => {
                                                $vm2.close('modal');
                                                },
                                            }">
                    <div class="row">
                        <div class="col">
    
                            <div class="l-form row">
                                <div class="col">
                                    <br />
                                    <div class="col-4">
                                        <h3>Event Name</h3>
                                        <input type="text" v-model="event" class="input" placeholder="Event Name">
                                    </div>
                                    <div class="col-4">
                                        <h3>Company</h3>
                                        <input type="text" v-model="company" class="input" placeholder="Madfun">
                                    </div>
                                    <div class="col-4">
                                        <h3>Venue</h3>
                                        <input type="text" v-model="venue" class="input" placeholder="Nairobi">
                                    </div>
                                </div>
                                <div class="col">
    
                                    <div class="col-4">
                                        <h3>Event Date</h3>
                                        <date-picker v-model="eventRange" type="datetime" range class="input inline"></date-picker>
                                    </div>
                                    <div class="col-4">
                                        <h3>Revenue Share</h3>
                                        <input type="number" v-model="revenueShare" class="input" placeholder="Nairobi">
                                    </div>
                                    <div class="col-4">
                                        <h3>Target</h3>
                                        <input type="number" v-model="target" class="input" placeholder="Nairobi">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="col-6">
                                        <h3>Poster Image</h3>
                                        <input type="file" multiple class="input" placeholder="Nairobi" accept="image/*" ref="file">
    
                                    </div>
                                    <div class="col-6">
                                        <button @click="submitFile" id="posterURL">Upload!</button>
                                    </div>
    
                                </div>
                                <div class="col">
                                    <div class="col-4">
                                        <h3>Categories</h3>
                                        <select v-model="categoryID">
                                                    <option value>Select Categories</option>
                                                    <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
                                                </select>
                                    </div>
                                    <div class="col-4">
                                        <h3>Is Public</h3>
                                        <select v-model="isPublic">
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                    </div>
                                    <div class="col-4">
                                        <h3>Multiple Shows</h3>
                                        <select v-model="hasMultipleShow">
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
    
                                    </div>
                                </div>
    
                                <div class="col">
                                    <h3>Description</h3>
                                    <textarea v-model="content" rows="5" cols="70"></textarea>
                                </div>
    
    
                            </div>
                        </div>
                    </div>
                </vue-modal-2>
            </div>
            <div class="row">
                <vue-modal-2 width="500" name="modal_edit" @on-close="closeEdit" :headerOptions="{
                                                title: 'Update: '+selectedEventsData.eventName,
                                            }" :footerOptions="{
                                                btn1: 'Cancel',
                                                btn2: 'Update Events',
                                                btn1Style: {
                                                backgroundColor: 'red',
                                                color:'white'
                                                },
                                                btn2OnClick: () => {
                                                    editEvent();
                                                },
                                                btn1OnClick: () => {
                                                $vm2.close('modal_edit');
                                                },
                                            }">
                    <div class="row">
                        <div class="col">
    
                            <div class="l-form row">
                                <div class="col">
                                    <br />
                                    <div class="col-4">
                                        <h3>Event Name</h3>
                                        <input type="text" v-model="selectedEventsData.eventName" class="input" placeholder="Event Name">
                                    </div>
                                    <div class="col-4">
                                        <h3>Company</h3>
                                        <input type="text" v-model="selectedEventsData.company" class="input" placeholder="Madfun">
                                    </div>
                                    <div class="col-4">
                                        <h3>Venue</h3>
                                        <input type="text" v-model="selectedEventsData.venue" class="input" placeholder="Nairobi">
                                    </div>
                                </div>
                                <div class="col">
    
                                    <div class="col-4">
                                        <h3>Event Date</h3>
                                        <date-picker v-model="eventRange" type="datetime" range class="input inline"></date-picker>
                                    </div>
                                    <div class="col-4">
                                        <h3>Revenue Share</h3>
                                        <input type="number" v-model="selectedEventsData.revenueShare" class="input" placeholder="Nairobi">
                                    </div>
                                    <div class="col-4">
                                        <h3>Target</h3>
                                        <input type="number" v-model="selectedEventsData.target" class="input" placeholder="Nairobi">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="col-6">
                                        <h3>Poster Image</h3>
                                        <input type="file" multiple class="input" placeholder="Nairobi" accept="image/*" ref="fileEdit">
    
                                    </div>
                                    <div class="col-6">
                                        <button @click="submitEditFile" id="posterURLEdit">Upload!</button>
                                    </div>
    
                                </div>
                                <div class="col">
                                    <div class="col-4">
                                        <h3>Categories</h3>
                                        <select v-model="selectedEventsData.category_id">
                                                    <option value>Select Categories</option>
                                                    <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
                                                </select>
                                    </div>
                                    <div class="col-4">
                                        <h3>Is Public</h3>
                                        <select v-model="selectedEventsData.isPublic">
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                    </div>
                                    <div class="col-4">
                                        <h3>Multiple Shows</h3>
                                        <select v-model="selectedEventsData.hasMultipleShow">
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
    
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <h3>Event Tag</h3>
                                        <input type="text" v-model="selectedEventsData.eventTag" class="input" placeholder="Event Name">
                                    </div>
                                    <div class="col-4">
                                        <h3>Affiliator</h3>
                                        <select v-model="selectedEventsData.hasAffiliator">
                                                <option value="1">Enable Affiliator</option>
                                                <option value="0">Disable Affiliator</option>
                                            </select>
                                    </div>
                                    <div class="col-4" style="align-items:center;">
                                        <h3>Status</h3>
                                        <select v-model="selectedEventsData.status">
                                                <option value="1">Active</option>
                                                <option value="4">Pending</option>
                                                <option value="3">Close</option>
                                            </select>
    
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h3>Description</h3>
                                        <textarea v-model="selectedEventsData.aboutEvent" rows="5" cols="70"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-modal-2>
            </div>
        </div>
    </div>
</template>

<style>
td.vuetable-slot {
    overflow: visible !important;
}

@media screen and (min-width: 370px) {
    .vm2_settings[data-v-11fe6c4e] {
        max-width: 650px;
    }
}
</style>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import loader from "@/components/loader"
import tableCss from "@/components/css.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import store from "../store";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        loader,
        DatePicker,
        VueEditor,

    },
    name: 'approvals',

    data() {
        return {
            content: "",
            errorcreateStatus: false,
            successcreateStatus: false,
            errorsMessage: "",
            revenueShare: 8,
            successMessage: "",
            venue: "",
            event: "",
            selectedEventsData: {
                eventName: "",
                company: "",
                venue: "",
                eventID: "",
                posterURL: "",
                hasMultipleShow: "",
                isPublic: "",
                aboutEvent: "",
                status: "",
                start_date: "",
                end_date: "",
                category_id: "",
                hasAffiliator:""
            },
            isPublic: 1,
            hasMultipleShow: 0,
            target: 1000,
            company: "",
            files: [],
            categoryID: "",
            categories: [],
            UploadStatusFailed: false,
            UploadStatusSuccess: false,
            uploadStatusMessage: "",
            modal: false,
            css: tableCss,
            loading: true,
            selected_data: {},
            stateAuditorExport: false,
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'glyphicon glyphicon-chevron-up',
                    descendingIcon: 'glyphicon glyphicon-chevron-down',
                    handleIcon: 'glyphicon glyphicon-menu-hamburger',
                },
            },
            columns: [{
                    name: "eventName",
                    title: 'Event',
                },
                {
                    name: "company",
                    title: 'Company',
                    sortField: 'company',
                },

                {
                    name: 'venue',
                    title: 'Venue',
                    sortField: 'venue'

                },

                {
                    name: 'status',
                    title: 'Status',
                    sortField: 'status'

                },
                {
                    name: 'start_date',
                    title: 'Event Date',
                    sortField: 'start_date',
                    titleClass: '',
                    dataClass: ''

                },
                {
                    name: 'action',
                    title: 'Action'
                }

            ],
            uploadStatus: false,
            range: '',
            eventRange: [],
            search_match: '',
            loadOnStart: true,
            sortOrder: [{
                field: 'created',
                direction: 'desc'
            }],
            is_partial_resulting: false,

            moreParams: {
                date: '',
                filter: '',
                end: ''
            },
            postURL: "",
            bannerURL: "",
            content: ""

        }
    },

    mounted() {

        var vm = this;
        if (!this.$cookies.get("key")) {
            this.$router.push({
                name: 'login'
            });
        }
        vm.viewCategory()

    },

    watch: {
        data(newVal, oldVal) {
            this.$refs.vuetable.refresh();
        },
        range() {

            var start = this.range[0];
            var end = this.range[1];
            start = moment(start).format('YYYY-MM-DD');
            end = moment(end).format('YYYY-MM-DD');
            this.moreParams.start = start;
            this.moreParams.end = end;
            this.$refs.vuetable.refresh();
        }
    },

    methods: {
        submitFile() {
            let vm = this
            var form = new FormData();
            if (vm.event == "") {
                vm.errorcreateStatus = true;
                vm.successcreateStatus = false;
                vm.errorsMessage = "Kindly enter event name"
            }
            $("#posterURL").text("Loading Please wait ...");
            form.append("api_key", "8e82513ce27f1b937fe66f2c5d8ba7c0");
            form.append("eventName", vm.event);
            form.append("file", vm.$refs.file.files[0]);

            $.post({
                url: store.state.rootUrl + "v1/api/event/upload/poster",
                type: "POST",
                data: form,
                cache: false,
                contentType: false,
                processData: false,
                success: function(response, status, jQxhr) {
                    console.log(response.code)
                    if (response.data.code != 200) {
                        $("#posterURL").text("Upload");
                        vm.postURL = ""
                    } else {
                        vm.postURL = response.data.URL
                        $("#posterURL").text("success");
                    }

                },
                error: function(jQxhr, status, error) {
                    $("#posterURLEdit").text("Upload");
                    vm.errorcreateStatus = true;
                    vm.successcreateStatus = false;
                    vm.errorsMessage = jQxhr.responseJSON.statusDescription;
                }
            })

        },
        submitEditFile() {
            let vm = this
            var form = new FormData();
            if (vm.event == "") {
                vm.errorcreateStatus = true;
                vm.successcreateStatus = false;
                vm.errorsMessage = "Kindly enter event name"
            }
            $("#posterURLEdit").text("Loading Please wait ...");
            form.append("api_key", "8e82513ce27f1b937fe66f2c5d8ba7c0");
            form.append("eventName", vm.selectedEventsData.eventName);
            form.append("file", vm.$refs.fileEdit.files[0]);

            $.post({
                url: store.state.rootUrl + "v1/api/event/upload/poster",
                type: "POST",
                data: form,
                cache: false,
                contentType: false,
                processData: false,
                success: function(response, status, jQxhr) {
                    console.log(response.code)
                    if (response.data.code != 200) {
                        $("#posterURLEdit").text("Upload");
                        vm.postURL = ""
                    } else {
                        vm.selectedEventsData.posterURL = response.data.URL
                        $("#posterURLEdit").text("success");
                    }

                },
                error: function(jQxhr, status, error) {
                    $("#posterURLEdit").text("Upload");
                    vm.errorcreateStatus = true;
                    vm.successcreateStatus = false;
                    vm.errorsMessage = jQxhr.responseJSON.statusDescription;
                }
            })

        },
        applyEvent() {
            this.$vm2.open('modal')

        },
        close() {
            this.$vm2.close('modal')
        },
        closeEdit() {
            this.$vm2.close('modal_edit')
        },
        viewRecords(props) {
            let vm = this
            vm.$cookies.set("selectedEvent",props);
            vm.$router.push({ name: 'view-events-dashboard', params: { id: props.eventID } });
        },
        selectedEvents(props) {
            let vm = this

            vm.selectedEventsData = props;
            vm.eventRange.push(props.start_date)
            vm.eventRange.push(props.end_date)

            this.$vm2.open('modal_edit')
        },

        viewCategory() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/view/category",
                type: "POST",
                data: JSON.stringify({
                    api_key: "4ba0e1aae090cdefc1887d2689b25e3f",
                    source: "WEB"

                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.categories = []
                    } else {
                        vm.categories = response.data.data
                    }

                },
                error: function(jQxhr, status, error) {
                    vm.categories = []
                }
            });

        },
        createEvents() {
            let vm = this
            $("#createType").html('<i class="fa fa-spinner fa-spin"></i> Loading...');

            $.post({
                url: store.state.rootUrl + "v1/api/event/create",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventName: vm.event,
                    company: vm.company,
                    venue: vm.venue,
                    target: vm.target,
                    start_date: vm.eventRange[0],
                    end_date: vm.eventRange[1],
                    revenueShare: vm.revenueShare,
                    posterURL: vm.postURL,
                    bannerURL: vm.bannerURL,
                    description: vm.content,
                    categoryID: vm.categoryID,
                    hasMultipleShow: vm.hasMultipleShow,
                    isPublic: vm.isPublic

                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        $("#createType").text("Create Events");
                        vm.errorcreateStatus = true;
                        vm.successcreateStatus = false;
                        vm.errorsMessage = response.data.message
                    } else {
                        vm.errorcreateStatus = false;
                        vm.successcreateStatus = true;
                        vm.successMessage = response.data.message
                        vm.close()
                        vm.$refs.vuetable.refresh();
                        $("#createType").text("Create Events");
                    }

                },
                error: function(jQxhr, status, error) {
                    $("#createType").text("Create Events");
                    vm.errorcreateStatus = true;
                    vm.successcreateStatus = false;
                    vm.errorsMessage = jQxhr.responseJSON.statusDescription;
                }
            });
        },
        editEvent() {
            let vm = this
            $("#createType").html('<i class="fa fa-spinner fa-spin"></i> Loading...');

            $.post({
                url: store.state.rootUrl + "v1/api/event/edit",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventID: vm.selectedEventsData.eventID,
                    eventName: vm.selectedEventsData.eventName,
                    company: vm.selectedEventsData.company,
                    venue: vm.selectedEventsData.venue,
                    target: vm.selectedEventsData.target,
                    start_date: vm.eventRange[0],
                    end_date: vm.eventRange[1],
                    revenueShare: vm.selectedEventsData.revenueShare,
                    posterURL: vm.selectedEventsData.posterURL,
                    description: vm.selectedEventsData.aboutEvent,
                    categoryID: vm.selectedEventsData.category_id,
                    hasMultipleShow: vm.selectedEventsData.hasMultipleShow,
                    isPublic: vm.selectedEventsData.isPublic,
                    status: vm.selectedEventsData.status,
                    eventTag: vm.selectedEventsData.eventTag,
                    hasAffiliator: vm.selectedEventsData.hasAffiliator

                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        $("#createType").text("Update Event");
                        vm.errorcreateStatus = true;
                        vm.successcreateStatus = false;
                        vm.errorsMessage = response.data.message
                    } else {
                        vm.errorcreateStatus = false;
                        vm.successcreateStatus = true;
                        vm.successMessage = response.data.message
                        vm.closeEdit()
                        vm.$refs.vuetable.refresh();
                        $("#createType").text("Update Event");
                    }

                },
                error: function(jQxhr, status, error) {
                    $("#createType").text("Update Event");
                    vm.errorcreateStatus = true;
                    vm.successcreateStatus = false;
                    vm.errorsMessage = jQxhr.responseJSON.statusDescription;
                }
            });
        },
        generateExport() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "ttp://35.187.164.231/madfun-api/v1/api/event/dashboard/view?export=1&start=" + vm.moreParams.start + "&end=" + vm.moreParams.end,
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.eventName = "Event";
                    item.company = "Company";
                    item.venue = "Venue";
                    item.start_date = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.class = response.data[i].name;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export').html('<i class="fa fa-download" aria-hidden="true"></i> Export Events');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_Report_" + exportDate);

                },
                error: function(jQxhr, status, error) {}
            });
        },
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
            //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

            var CSV = '';
            //Set Report title in first row or line

            //CSV += ReportTitle + '\r\n\n';

            //This condition will generate the Label/Header
            if (ShowLabel) {
                var row = "";

                //This loop will extract the label from 1st index of on array
                for (var index in arrData[0]) {

                    //Now convert each value to string and comma-seprated
                    row += index + ',';
                }

                row = row.slice(0, -1);

                //append Label row with line break
                CSV += row + '\r\n\n\n';
            }

            //1st loop is to extract each row
            for (var i = 0; i < arrData.length; i++) {
                var row = "";

                //2nd loop will extract each column and convert it in string comma-seprated
                for (var index in arrData[i]) {
                    row += '"' + arrData[i][index] + '",';
                }

                row.slice(0, row.length - 1);

                //add a line break after each row
                CSV += row + '\r\n';
            }

            if (CSV == '') {
                alert("Invalid data");
                return;
            }

            //Generate a file name
            var fileName = "Kenaff_";
            //this will remove the blank-spaces from the title and replace it with an underscore
            fileName += ReportTitle.replace(/ /g, "_");

            //Initialize file format you want csv or xls
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

            // Now the little tricky part.
            // you can use either>> window.open(uri);
            // but this will not work in some browsers
            // or you will not get the correct file extension

            //this trick will generate a temp <a /> tag
            var link = document.createElement("a");
            link.href = uri;

            //set the visibility hidden so it will not effect on your web-layout
            link.style = "visibility:hidden";
            link.download = fileName + ".csv";

            //this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        dateFilter: function(start, end) {

            var vm = this;

            vm.start = start;
            vm.end = end;

            vm.moreParams.start = start;
            vm.moreParams.end = end;

            this.$refs.vuetable.refresh();

            console.log("start date filter", start)
            console.log("end date filter ", end)
        },
        createdAt: function(value) {
            if (!value) {
                return '-';
            } else {
                return moment(value).format('h:mm a,DD MMM YYYY');
            }

        },

        searchRecord: function() {
            this.moreParams.filter = this.search_match;
            this.$refs.vuetable.refresh();
        },

        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;

            let local = this.data;

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                );
            }

            pagination = this.$refs.vuetable.makePagination(
                local.length,
                this.perPage
            );
            console.log('pagination:', pagination)
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },

        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onLoading() {
            this.loading = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            this.loading = false
            console.log('loaded! .. hide your spinner here');
            $(".ui.dropdown").dropdown();
        },

    },
    filters: {
        formatCapitalize1st(value) {
            if (value != null) {
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
            return ""
        },
        formatStatus(value) {
            if (value != null) {
                if (value == 1) {
                    return "Active"
                } else if (value == 2) {
                    return "Cancel"
                } else if (value == 3) {
                    return "Closed"
                } else {
                    return "Pending"
                }

            }
            return ""
        }
    }

}
</script>
