import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import "../node_modules/normalize.css/normalize.css"
import "@/assets/scss/app.scss"
import 'vue-js-modal/dist/styles.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import Modal from "@burhanahmeed/vue-modal-2";

Vue.use(VueSweetalert2);

Vue.use(VueCookies);
Vue.use(VModal)
Vue.use(Modal);

Vue.$cookies.config('7d');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
